<app-message></app-message>
<app-loader></app-loader>
<router-outlet></router-outlet>
<footer style="border-top: 1px lightgray solid; top: 94vh; width: 100%; z-index: 99;background-color: white;margin-top: 12px;">
  <div class="container">
  <div class="row">
    <div class="col-10">
    <p class="text-center" style="margin-top:0.5rem; margin-bottom: 0.5rem;">
      Copyright © 2017-{{currentYear}},
      <a href="https://inlore.com/">Inlore Technologies Private Limited</a>. All rights
      reserved.
    </p>
    </div>
    <div class="col-2">
    <p class="text-right" style="margin-top:0.5rem; margin-bottom: 0.5rem;">
      <a href="https://inlore.com/" target="_blank">Powered by
        Editaware
      </a>
    </p>
  </div>
  </div>
</div>
</footer>