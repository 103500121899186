<app-menu></app-menu>
<div class="container">
  <ol class="breadcrumb mb-2">
    <li class="breadcrumb-item">{{'login.breadcrumb.item1' | translate }}</li>
    <li class="breadcrumb-item">{{'login.breadcrumb.item2' | translate }}</li>
  </ol>
  <div class="row">
    <div class="col-md-4 mobile-padding-top">
      <div class="card  ">
        <div class="card-header">
          <h6 >
            <i class="fa fa-sign-in"></i> {{'login.title' | translate }}
          </h6>
        </div>
        <div class="card-body"> 
          <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
            <div class="form-group">
              <div class="form-floating" [ngClass]="{'is-invalid': formGroup.controls['Email'].invalid && formGroup.controls['Email'].dirty}">
                <input type="email" class="form-control" [ngClass]="{'is-invalid': formGroup.controls['Email'].invalid && formGroup.controls['Email'].dirty}" formControlName="Email" id="floatingInputEmail" autocomplete="username" maxlength="50" placeholder="{{'login.loginForm.email.control_label' | translate}}">
                <label for="floatingInputEmail"  translate>login.loginForm.email.control_label</label>
              </div>
              <div class="invalid-feedback" *ngIf="formGroup.controls['Email'].invalid && formGroup.controls['Email'].dirty">
                <span *ngIf="formGroup.controls['Email'].errors.required">{{'login.loginForm.email.validation.required' | translate }}</span>
                <span *ngIf="formGroup.controls['Email'].errors.pattern && !formGroup.controls['Email'].errors.maxlength && !formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.invalid' | translate }}</span>
                <span *ngIf="formGroup.controls['Email'].errors.maxlength">{{'login.loginForm.email.validation.maxlength' | translate }}</span>
                <span *ngIf="formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.minlength' | translate }}</span>
              </div>
            </div>
            <div class="form-group">
              <div class="form-floating" [ngClass]="{'is-invalid': formGroup.controls['Password'].invalid && formGroup.controls['Password'].dirty }">
                <input type="password" [ngClass]="{'is-invalid': formGroup.controls['Password'].invalid && formGroup.controls['Password'].dirty}" class="form-control" formControlName="Password" maxlength="30" autocomplete="current-password" id="floatingInputPassword" autocomplete="username" placeholder="{{'login.loginForm.password.control_label' | translate }}">
                <label for="floatingInputPassword">{{'login.loginForm.password.control_label' | translate }}</label>
              </div>
              <div class="invalid-feedback" *ngIf="formGroup.controls['Password'].invalid && formGroup.controls['Password'].dirty">
                <span *ngIf="formGroup.controls['Password'].errors.required">{{'login.loginForm.password.validation.required' | translate }}</span>
                <span *ngIf="formGroup.controls['Password'].errors.maxlength">{{'login.loginForm.password.validation.maxlength' | translate }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a (click)="goToForgotPassword()" class="text-primary pull-left m-1">{{'login.loginForm.forgotPasswordBtn' | translate }}</a>
              </div>
              <div class="col">
                <button class="btn btn-primary pull-right" type="submit">{{'login.loginForm.loginBtn' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-4 mobile-padding-top">
      <div class="card  ">
        <div class="card-header">
          <h6 >
            {{'login.otherwaysToLoginPanel.title' | translate }}
          </h6>
        </div>
        <div class="card-body text-center">
          <div id="googleBtn" class="g-signin2" data-onsuccess="onSignIn">
            login
          </div>
          <!-- <div class="g-signin2" data-onsuccess="onSignIn"></div> -->
          <div >
              <a class=" text-primary font-12" (click)="onOpenNotAbleToSignInModal(notAbleToSignInModal)">{{'login.otherwaysToLoginPanel.googleLoginError' | translate }}</a>
          </div>
          <div class="mt-1">
            <button class="btn btn-primary" (click)="onOpenOtpModal(otpModal)" type="button">{{'login.otherwaysToLoginPanel.otpLoginBtn' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mobile-padding-top">
      <div class="card  ">
        <div class="card-header">
          <h6 >{{'login.noLoginYetPanel.title' | translate }}</h6>
        </div>
        <div class="card-body">
          <p class="text-justify">{{'login.noLoginYetPanel.content' | translate }}</p>
          <button class="btn btn-info pull-right" (click)="goToRegister()">{{'login.noLoginYetPanel.buttonName' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #registerModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">{{'login.registerModal.title' | translate }}</h6>
        <button type="button" class="btn-close" (click)="registerModal.hide()" aria-label="Close">
         
        </button>
      </div>
      <div class="modal-body">
      {{'login.registerModal.bodyContent' | translate:{googleEmail:googleEmail} }}
        <div class="form-group">
            <span class="input-group">
              <span class="input-group-prepend input-group-text">
                 
                <p-checkbox [(ngModel)]="registerCheckboxValue" binary="true"></p-checkbox>
              </span>
              <span class="form-control   height-auto">
               {{'login.registerModal.checkboxContent' | translate }} </span>
            </span>
    </div>
      </div>
      <div class="modal-footer">
        <div class="pull-right">
          <button (click)="registerModal.hide()" type="button" class="btn btn-outline-primary">{{'login.registerModal.cancelBtn' | translate }}</button>
          <button [disabled]="!registerCheckboxValue" (click)="registerUserFromGoogle()" type="button" class="btn btn-primary">{{'login.registerModal.loginBtn' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #otpModal>
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{'login.otpModal.title' | translate }}</h6>
    <button type="button" class="btn-close pull-right" aria-label="Close" (click)="onHideOtpModal()">
     
    </button>
  </div>
  <form [formGroup]="sendOtpFormGroup" (ngSubmit)="onSubmitSendOtpFormGroup(sendOtpFormGroup.value)">
    <div class="modal-body" *ngIf="!isShowOtpFormGroup && !isUserNotRegistered">
            <div class="form-group">
              <div class="form-floating" [ngClass]="{'is-invalid': sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty}">
                <input type="email" [ngClass]="{'is-invalid': sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty}" name="email" class="form-control" formControlName="email" maxlength="50"  id="floatingInputEmail"  placeholder="{{'login.sendOtpForm.email.control_label' | translate }}">
                <label for="floatingInputEmail">{{'login.sendOtpForm.email.control_label' | translate }}</label>
              </div>
              <div class="invalid-feedback" *ngIf="sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty">
                <span *ngIf="sendOtpFormGroup.controls['email'].errors.required">{{'login.sendOtpForm.email.validation.required' | translate }}</span>
                <span *ngIf="sendOtpFormGroup.controls['email'].errors.pattern && !sendOtpFormGroup.controls['email'].errors.required && !sendOtpFormGroup.controls['email'].errors.maxlength && !sendOtpFormGroup.controls['email'].errors.minlength">{{'login.sendOtpForm.email.validation.invalid' | translate }}</span>
                <span *ngIf="sendOtpFormGroup.controls['email'].errors.minlength && !sendOtpFormGroup.controls['email'].errors.required">{{'login.sendOtpForm.email.validation.minlength' | translate }}</span>
                <span *ngIf="sendOtpFormGroup.controls['email'].errors.maxlength">{{'login.sendOtpForm.email.validation.maxlength' | translate }}</span>
              </div>
            </div>
          </div>
            <div *ngIf="!isShowOtpFormGroup && !isUserNotRegistered" class="modal-footer">
                    <button (click)="onHideOtpModal()" class="btn btn-outline-primary" type="button">{{'login.otpModal.closeBtn' | translate }}</button>
                    <button class="btn btn-primary" type="submit">{{'login.otpModal.sendBtn' | translate }}</button>
            </div>
            
        </form>
    <div class="modal-body" *ngIf="isUserNotRegistered">
      {{'login.otpModal.userNotRegistered' | translate:{email:sendOtpFormGroup.value.email} }}
     <br> 
     <div class="form-group">
              <span class="input-group">
                <span class="input-group-prepend input-group-text">
                    <p-checkbox [(ngModel)]="registerCheckboxValue" binary="true"></p-checkbox>
                </span>
                <span class="form-control   height-auto">
                 {{'login.otpModal.checkboxContent' | translate }}</span>
              </span>
      </div>
    </div>
      <div  *ngIf="isUserNotRegistered" class="modal-footer">
              <button (click)="onHideOtpModal()" class="btn btn-outline-primary" type="button">{{'login.otpModal.cancelBtn' | translate }}</button>
              <button [disabled]="!registerCheckboxValue" (click)="onConfirmRegisterWithOtp()" class="btn btn-primary" type="button">{{'login.otpModal.confirmBtn' | translate }}</button>   
      </div>
      <div class="modal-body" *ngIf="isShowOtpFormGroup && !isUserNotRegistered">
        <form [formGroup]="oneTimePasswordFormGroup" (ngSubmit)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)">
            <div class="form-group">
                <label class="col-form-label" translate>{{'login.oneTimePasswordForm.email.control_label' | translate }}</label>
                <div class="form-control ">{{sendOtpFormGroup.value.email}}</div>
              <div class="form-text text-muted">{{'login.oneTimePasswordForm.email.helpText' | translate }}</div>
              </div>
            <div class="form-group">
              <div class="form-floating" [ngClass]="{'is-invalid': oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty}">
                <input type="text" [ngClass]="{'is-invalid': oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty }"  class="form-control" formControlName="otpContent" maxlength="50"  id="floatingInputOtp"  placeholder="{{'login.sendOtpForm.otp.control_label' | translate }}">
                <label for="floatingInputOtp">{{'login.sendOtpForm.otp.control_label' | translate }}</label>
              </div>
              <div class="invalid-feedback" *ngIf="oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty">
                  <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.required">{{'login.sendOtpForm.otp.validation.required' | translate }}</span>
                  <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.minlength && !oneTimePasswordFormGroup.controls['otpContent'].errors.required">{{'login.sendOtpForm.otp.validation.minlength' | translate }}</span>
                  <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.maxlength">{{'login.sendOtpForm.otp.validation.maxlength' | translate }}</span>
                </div>
            </div>              
            <div class="form-group  text-center">
                <button class="btn btn-primary margin-right-1" (click)="onClickResendOtp()" type="button">{{'login.oneTimePasswordForm.resendBtn' | translate }}</button>              
              <button class="btn btn-primary" type="submit">{{'login.oneTimePasswordForm.confirmBtn' | translate }}</button>
            </div>
          </form>
        </div>    
  
</ng-template>
<ng-template #notAbleToSignInModal>
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{'login.notAbleToSignInModal.title' | translate }}
    </h6>
    <button type="button" class="btn-close pull-right" aria-label="Close"
      (click)="modalRef.hide()">
     
    </button>
  </div>
  <div class="modal-body">{{'login.notAbleToSignInModal.modalContent' | translate }}
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" class="btn pull-right btn-outline-primary">{{'login.notAbleToSignInModal.closeBtn' | translate }}</button>
  </div>
  </ng-template>