import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MessageService } from './message.service';
// tslint:disable-next-line:interface-name
type Severities = 'success' | 'warning' | 'error';
// tslint:disable-next-line:interface-name
interface Notification {
  severity: Severities;
  summary: string;
  detail: string;
  timeOut: number;

}
@Component({
  selector: 'app-message',
  styleUrls: ['./message.component.scss'],
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit, OnDestroy {
  public subscription: Subscription;

  constructor(private toastrService: ToastrService, private messageService: MessageService) {
    this.subscribeToNotifications();

   }

  public ngOnInit(): void {
  }
  public subscribeToNotifications() {
    this.subscription = this.messageService.notificationChange
    .subscribe((notification: Notification) => {
     this.toastrService.clear();
     this.toastrService[notification.severity](notification.summary, notification.detail , {
      timeOut: notification.timeOut,
    });

    });
  }
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}