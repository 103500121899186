import { ModuleWithProviders, NgModule } from '@angular/core';
import { CalendarUtilsService } from '../../src/utils/calendar.utils';
// import { DragDropDirective } from '../directives/dragdrop.directive';
import { LoaderService } from '../core/loader/loader.service';
import { MessageService } from '../core/message/message.service';
import { RestService } from 'src/core/rest.service';
import { ArticleService } from './article.service';
import { AuthService } from './auth.service';
import { ClientService } from './client.service';
import { CommunicationService } from './communication.service';
import { GroupService } from './group.service';
import { IssuesService } from './issues.service';
import { JournalService } from './journal.service';
import { TagService } from './tag.service';
import { TaskService } from './task.service';
import { UserService } from './user.service';
import { WorkflowService } from './workflow.service';
import { DragDropDirective } from 'src/core/directives/dragdrop.directive';
import { IframeAutoHeightDirective } from 'src/core/directives/iframeautoheight.directive';



// to share single instance of services across all modules and components
@NgModule({})
export class SharedServiceModule {
  public static forRoot(): ModuleWithProviders<SharedServiceModule> {
    return {
      ngModule: SharedServiceModule,
      providers: [
        DragDropDirective,
        IframeAutoHeightDirective,
        ArticleService,
        ClientService,
        CommunicationService,
        GroupService,
        IssuesService,
        JournalService,
        TagService,
        WorkflowService,
        TaskService,
        RestService,
        LoaderService,
        MessageService,
        AuthService,
        UserService,
        CalendarUtilsService,
      ],
    };
  }
}
