import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/user/login';
import { NoContentComponent } from './components/no-content';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'register', loadChildren: () => import('./components/user/register/register.module').then( (m) => m.RegisterModule) },
  { path: 'resetpassword', loadChildren: () => import('./components/user/resetpassword/resetpassword.module').then( (m) => m.ResetPasswordModule) },
  { path: 'about', loadChildren: () => import('./components/about/about.module').then( (m) => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./components/contact/contact.module').then( (m) => m.ContactModule) },
  { path: 'home', loadChildren: () => import('./components/user/home/home.module').then( (m) => m.HomeModule) },
  { path: 'account', loadChildren: () => import('./components/user/profileupdate/account.module').then( (m) => m.AccountModule) },
  { path: 'journal', loadChildren: () => import('./components/user/article/article.module').then( (m) => m.ArticleModule) },
  { path: 'ckeditor', loadChildren: () => import('./components/user/ckeditor-fullscreen-view/ckeditorfullscreen.module').then( (m) => m.CkEditorFullScreenModule) },
  { path: 'article', loadChildren: () => import('./components/workflow/workflow/workflow.module').then( (m) => m.WorkflowModule) },
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then( (m) => m.AdminModule) },
  { path: 'forgotpassword', loadChildren: () => import('./components/forgotpassword/forgotpassword.module').then( (m) => m.ForgotPasswordModule) },
  { path: 'externalUserInvitation', loadChildren: () => import('./components/externalcollaboration/externalcollaboration.module').then( (m) => m.ExternalCollaborationModule) },
  { path: '**', component: NoContentComponent},
];  
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
