import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkflowService {
  constructor( private restService: RestService) { }
  public completeEditorReview(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeEditorReview/' + id, data).pipe(map((res: any) => res));
  }
  public completeConsolidatedReview(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeConsolidatedReviewTask/' + id, data).pipe(map((res: any) => res));
  }
  public completeInitialReview(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeInitialReview/' + id, data).pipe(map((res: any) => res));
  }
  public completeReviseManuscript(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeReviseManuscript/' + id, data).pipe(map((res: any) => res));
  }
  public completeCopyedit(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeCopyedit/' + id, data).pipe(map((res: any) => res));
  }
  public completeAuthorReviewTask(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeAuthorReviewTask/' + id, data).pipe(map((res: any) => res));
  }
  public completeInitiatePaymentTask(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeInitiatePaymentTask/' + id, data).pipe(map((res: any) => res));
  }
  public initiatePayment(data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/initiatePayment' , data).pipe(map((res: any) => res));
  }
  public completePaymentTask(id ,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completePaymentTask/' + id, data).pipe(map((res: any) => res));
  }
  public completeVerifyPayment(id ,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/completeVerifyPayment/' + id, data).pipe(map((res: any) => res));
  }
  public getWorkflowCommentHistory(articaNodeID) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/comments/' + articaNodeID, null).pipe(map((res: any) => res));
  }
  public reassignTask(taskId, assigneeId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/reassignTask/' + taskId + '/' +  assigneeId, null)
    .pipe(map((res: any) => res));
  }
  public restartWorkflow(data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/editorReview' , data, null)
    .pipe(map((res: any) => res));
  }
  public getpendingTasks(journalId, manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/pendingTasks/' + journalId + '/' +  manuscriptId, null)
    .pipe(map((res: any) => res));
  }
  public taskAssignees(taskId, journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/taskAssignees/' + taskId + '/' +  journalId, null)
    .pipe(map((res: any) => res));
  }
  public PeerReviewInvitationSubmit(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/acceptOrDeclinePanelReviewInvitation/' + taskId, data)
    .pipe(map((res: any) => res));
  }
  public getfeedBackCommentHistroy(taskId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/reviewerResponses/' + taskId   , null)
    .pipe(map((res: any) => res));
  }
  public getreviewerResponses(journalId,manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/admin/reviewerResponses/' + journalId + '/' + manuscriptId   , null)
    .pipe(map((res: any) => res));
  }
  public getReviewdVersions(journalId, manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/workflow/reviewCycle/' + journalId + '/' + manuscriptId  , null)
    .pipe(map((res: any) => res));
  }
  public getMigrationData() {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/migration' , null).pipe(map((res: any) => res));
  }
  public deleteThisTask(processId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/workflow/cancel/' +processId , null).pipe(map((res: any) => res));
  }
  public sendtaskreminder(taskId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/message/' + taskId, data)
    .pipe(map((res: any) => res));
  }
  public asignMorePanelReviewers(data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/panelReview', data).pipe(map((res: any) => res));
  }
  public startPanelReview(data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/panelReview' , data).pipe(map((res: any) => res));
  }
}
