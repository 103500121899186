import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Router,
} from '@angular/router';
import {
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  BsModalRef,
} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {
  CookieService,
} from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { CommunicationService } from 'src/services/communication.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-menu',
  styleUrls: ['./menu.component.css'],
  templateUrl: './menu.component.html',
})

export class MenuComponent implements OnInit {

  public config = {
    animated: true,
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
  };
  public adminAuthority: any;
  public userName: any;
  public modalRef: BsModalRef;
  public isSuperAdmin = false;
  public isUserLoggedIn: boolean;
  public navbarOpen = false;
  currentJournal='jmust';
  isEditor = false;
  userId;
  constructor(public authService: AuthService, public communicationService: CommunicationService,
              public router: Router, public modalService: BsModalService,
              public cookieService: CookieService,public userService:UserService) {
              }
              public toggleNavbar() {
                this.navbarOpen = !this.navbarOpen;
              }
              public ngOnInit() {
                this.getUserDetails();
     }
  public goToAdminJournalManagement() {
    this.router.navigate(['/admin/journal/management']);
  }
  public goToJournals() {
    this.router.navigate(['/home/journals']);
  }
  public goToAdminUsers() {
    this.router.navigate(['/admin/users']);
  }
  public onClickHome() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/login']);
    }
  }
  public onClickLogo() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/login']);
    }

  }
  public gotoAdminClients() {
    this.router.navigate(['admin/clients']);
  }
  public gotoAdminGroups() {
    this.router.navigate(['admin/groups']);
  }
  public gotoAdminTags() {
    this.router.navigate(['admin/tags']);
  }
  public goToSuperAdminHome() {
    this.router.navigate(['admin/home']);
  }
  public confirmLogoutModal(logout) {
    this.modalRef = this.modalService.show(logout, this.config);
  }
  public goToRegister() {
    this.router.navigate(['/register']);

  }
  public logoutConfirm() {
    this.authService.logout().subscribe((res) => {
      this.modalRef.hide();
      this.cookieService.deleteAll();
      sessionStorage.clear();
      this.communicationService.onlogoutUser();
      this.router.navigate(['/login']);
    });
  }
  public goToAbout(link) {
    this.router.navigate([link]);
  }
  public goToContact(link) {
    this.router.navigate([link]);
  }
  public onChangePassword(link) {
    this.router.navigate([link]);
  }
  public onUpdateProfile(link) {
    this.router.navigate([link]);
  }
public goToAllTasks() {
  this.router.navigate(['/home/mytasks']);
}
public goToAllSubmissions() {
  this.router.navigate(['/admin/journal/submissions'], { queryParams: { name: this.currentJournal} });
}
public goToAllArticles() {
  this.router.navigate(['/home/myarticles']);
}
public getUserDetails() {
  this.communicationService.getUserName.subscribe( (userId) => {
    this.userName = userId;

  });
  this.communicationService.getUserId.subscribe( (userId) => {
    this.userId = userId;
    this.isUserLoggedIn = (userId != null) ? true : false;
    if (this.isUserLoggedIn) {
      this.isSuperAdmin =  ( userId == 'admin') ? true : false;
    }
  
  });
  this.communicationService.getAdminAuthority.subscribe( (adminAuthority) => {
    this.adminAuthority = adminAuthority;
  });
  let isEditorAuthority = this.cookieService.get('isEditor');
  if(isEditorAuthority) {
    this.isEditor = (isEditorAuthority === 'true'); ;
  }
  if(this.userId && (isEditorAuthority == undefined || isEditorAuthority == '')) {
    this.userService.isLogineduserIsEditor(this.userId).subscribe((res) => {
      this.isEditor = res.response.editor || false;
      this.cookieService.set('isEditor', this.isEditor.toString())
    });
  }
}
}
