import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA, LOCALE_ID, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageComponent } from 'src/core/message/message.component';
import { LoaderComponent } from 'src/core/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { RestService } from 'src/core/rest.service';
import { CookieService } from 'ngx-cookie-service';
import { CommunicationService } from 'src/services/communication.service';
import { LoaderService } from 'src/core/loader/loader.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from '../services/sharedservice.module';
import { ToastrModule } from 'ngx-toastr';
import { MessageService } from 'primeng/api';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/user/login';
import { I18nService } from 'src/core/i18n.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpTranslateLoaderFactory } from 'src/core/i18n.loader';
import { MenuModule } from './components/menu/menu.module';
import { NoContentComponent } from './components/no-content';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { GlobalErrorHandler } from 'src/core/globalErrorHandler';
// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS
];
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    MessageComponent,
    LoaderComponent,
    LoginComponent,
    NoContentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ToastModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    InputTextModule,
    SharedModule,
    SharedServiceModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpTranslateLoaderFactory,
          deps: [HttpClient]
      }
  }),
  AppRoutingModule,
    MenuModule, // Common Module for Menu and pipes
    CheckboxModule, AccordionModule.forRoot(),
  ],
  providers: [
    LoaderService,
    I18nService,
    MessageService,
    CookieService,
    APP_PROVIDERS,
    // {
    //   deps: [I18nService],  //  service handling global settings
    //   provide: LOCALE_ID,
    //   useFactory: (I18nService) => I18nService.language  //  locale string
    // },
    RestService,
    CookieService,
    I18nService,
    CommunicationService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
})
export class AppModule {
  constructor() { }
}

