import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MenuComponent } from './menu.component';
import { SafePipe } from 'src/utils/safe.pipe';
import { EscapeHtmlPipe } from 'src/core/pipes/keep-html.pipe';
import { FootnoteScroll } from 'src/core/directives/footnotescroll.directive';
// import { DateFormatPipe } from 'src/core/pipes/dateFormat.pipe';
// Menu Module has all the common components to be used by all the other module's
@NgModule({
  declarations: [
    MenuComponent,
    SafePipe,
    EscapeHtmlPipe,
    FootnoteScroll,
    // DateFormatPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  exports: [
    MenuComponent,
    SafePipe,
    EscapeHtmlPipe,
    FootnoteScroll,
    TranslatePipe,
    // DateFormatPipe,
    // IframeContentLoaderComponent,
  ],
})
export class MenuModule { }
