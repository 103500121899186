import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DragDropDirective } from 'src/core/directives/dragdrop.directive';
import { IframeAutoHeightDirective } from 'src/core/directives/iframeautoheight.directive';
import { DateFormatPipe } from 'src/core/pipes/dateFormat.pipe';
import { SafePipe } from 'src/utils/safe.pipe';

// import { DragDropDirective } from 'directives/dragdrop.directive';
// import { TextUpperCaseDirective } from 'directives/textcapitalize.directive';

@NgModule({
   declarations: [
    DateFormatPipe,
    DragDropDirective,
    IframeAutoHeightDirective
   ] ,
  exports: [
    DragDropDirective,
    IframeAutoHeightDirective,
    // TextUpperCaseDirective,
    ModalModule,
    DateFormatPipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class SharedModule  { }
