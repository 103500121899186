import {
  Injectable,
  Inject
} from '@angular/core';

import {
  RestService
} from '../core/rest.service';

import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';
@Injectable()
export class GroupService {

  constructor(
    private restService: RestService) {

    }
    public getGroups() {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group', true).pipe(map((res: any) => res));
    }
    public getAllGroups() {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/allGroupsAndSubGroups', true).pipe(map((res: any) => res));
    }
    public editGroupDetails(value) {
      const  data = {
        displayName: value.displayName
      };
      return this.restService.put(AppConfig.BASE_URL + '/editaware/group/' + value.shortName, data, true).pipe(map((res: any) => res));
    }
    public getGroupDetails(shortName) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/subGroups/' + shortName, true).pipe(map((res: any) => res));
    }
    public getUserGroups(id) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/user/' + id, true).pipe(map((res: any) => res));
    }
    public deleteUser(groupShortName, userid) {
      return this.restService.delete(AppConfig.BASE_URL + '/editaware/group/' + groupShortName + '/' + userid, true).pipe(map((res: any) => res));
    }
    public addUser(values) {
      const data = {
        userId : values.id
      };
      return this.restService.post(AppConfig.BASE_URL + '/editaware/group/user/' + values.groupName + '/' + values.id, true).pipe(map((res: any) => res));
    }
    public addUserFromGroup(values) {
      const data = {
        userId : values.id
      };
      return this.restService.post(AppConfig.BASE_URL + '/editaware/group/user/' + values.groupName + '/' + values.id, true).pipe(map((res: any) => res));
    }
    public addSubGroup(parentGroupName, values) {
      return this.restService.post(AppConfig.BASE_URL + '/editaware/group/' + parentGroupName + '/' + values.shortName, true).pipe(map((res: any) => res));
    }
    public getPeerReviewerMembers(journalId,isTrue = false) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/peerReviewers/' + journalId, true,isTrue).pipe(map((res: any) => res));
    }
    public getInitialReviewersMembers(journalId) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/initialReviewers/' + journalId, true).pipe(map((res: any) => res));
    }
    public getcopyEditorMembers(journalId) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/copyEditor/' + journalId, true).pipe(map((res: any) => res));
    }
}
