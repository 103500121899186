<nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3 mt-0 p-0 mobile-padding-menu tab-padding-menu">
  <div class="container">
    <a (click)="onClickLogo()" class="navbar-brand">
      <img src="../assets/img/logo.png">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link" (click)="onClickHome()" translate>menu.home</a></li>
        <li *ngIf="isUserLoggedIn" class="nav-item"><a class="nav-link" (click)="goToJournals()"
            translate>menu.journals</a></li>
        <span *ngIf="adminAuthority === true" class="nav-item btn-group  color-menu-item" dropdown>
          <a class="nav-link" id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown"
            translate>menu.admin <i class="fa fa-caret-down"></i></a>
          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu bg-dark text-white" role="menu"
            aria-labelledby="basic-link">
            <li class="dropdown-item" (click)="goToSuperAdminHome()"><a class="dropdown-link"
                translate>menu.dashboard</a></li>
            <li (click)="goToAdminJournalManagement()" class="dropdown-item"><a class="dropdown-link"
                translate>menu.journals</a></li>
            <li (click)="gotoAdminClients()" class="dropdown-item"><a class="dropdown-link" translate>menu.clients</a>
            </li>
            <li role="separator" class="divider"></li>
            <li (click)="goToAdminUsers()" class="dropdown-item"><a class="dropdown-link" translate>menu.users</a></li>
            <li (click)="gotoAdminGroups()" class="dropdown-item"><a class="dropdown-link" translate>menu.groups</a>
            </li>
            <!-- <li (click)="gotoAdminTags()" class="dropdown-item"><a class="dropdown-link" translate>menu.tags</a>
            </li> -->
          </ul>
		</span>
    <li *ngIf="isUserLoggedIn && (isEditor || isSuperAdmin || adminAuthority)"  class="nav-item"><a class="nav-link" (click)="goToAllSubmissions()" translate>menu.allSubmissions</a></li>
        <li *ngIf="isUserLoggedIn"  class="nav-item"><a class="nav-link" (click)="goToAllTasks()" translate>menu.tasks</a></li>
        <li *ngIf="isUserLoggedIn"  class="nav-item"><a class="nav-link" (click)="goToAllArticles()" translate>menu.articles</a></li>		
        <li class="nav-item"><a class="nav-link" (click)="goToAbout('/about')" translate>menu.about</a></li>
        <li class="nav-item"><a class="nav-link" (click)="goToContact('/contact')" translate>menu.contact</a></li>
      </ul>
        <!-- <div class="alert alert-warning mt-2 ml-3">
          The system will be under scheduled maintenance and not available<br>
          between <strong>Dec 3, 2021 9PM and Dec 4, 2021 5AM</strong> Manila time. 
        </div> -->
      <ul class="navbar-nav  ml-auto">

        <span *ngIf="isUserLoggedIn" class="nav-item btn-group  color-menu-item" dropdown>
          <a href id="basic-link" class="nav-link" dropdownToggle (click)="false"
            aria-controls="basic-link-dropdown">{{'menu.greeting' | translate:{userName:userName} }}&nbsp;<i
              class="fa fa-caret-down"></i></a>
          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu bg-dark text-white" role="menu"
            aria-labelledby="basic-link">
            <li (click)="onUpdateProfile('/account/changepassword')" class="dropdown-item"><a class="dropdown-link"
                translate>menu.changePassword</a></li>
            <li (click)="onUpdateProfile('/account')" class="dropdown-item"><a class="dropdown-link"
                translate>menu.updateProfile</a></li>
          </ul>
        </span>

        <li *ngIf="isUserLoggedIn" class="nav-item"><button class="btn btn-sm btn-info btn-login"
            (click)="confirmLogoutModal(logout)" id="logout"><i class="fa fa-sign-in"></i>
            {{'menu.logout' | translate }}</button></li>
        <li *ngIf="!isUserLoggedIn" class="nav-item mt-1"><button class="btn btn-sm btn-info btn-login"
            (click)="goToRegister()" id="logout"><i class="fa fa-sign-in"></i>
            {{'menu.registerBtn' | translate }}</button></li>
      </ul>
    </div>
  </div>
</nav>
<ng-template #logout>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'menu.logoutModal.title' | translate }}</h4>
    <button type="button" class="btn-close"  aria-label="Close" (click)="modalRef.hide()">
    </button>
  </div>
  <div class="modal-body">{{'menu.logoutModal.body' | translate }}</div>
  <div class="modal-footer">
    <button (click)="logoutConfirm()"
      class="btn pull-right btn-outline-primary margin-left-5">{{'menu.logoutModal.okBtn' | translate }}</button>
    <button (click)="modalRef.hide()"
      class="btn pull-right btn-outline-primary">{{'menu.logoutModal.cancel' | translate }}</button>
  </div>
</ng-template>
