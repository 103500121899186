import { AfterViewInit, Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfig } from 'config/app.config';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { CommunicationService } from 'src/services/communication.service';
import { UserService } from 'src/services/user.service';
import { validateAllFormFields } from 'src/utils/validateform';



declare const gapi: any;
@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit , AfterViewInit , OnDestroy {
  public localState = { value: '' };
  public auth2: any;
  public formGroup: FormGroup;
  @ViewChild('registerModal', {static: false}) public registerModal: ModalDirective;
  public config = {
    animated: true,
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
  };
      // TO MAKE RESPONSIVE
    public isCollapsed = true;
  public lastUrl: any;
  public googleEmail: any;
  public googleToken: any;
  public modalRef: any;
  public sendOtpFormGroup: FormGroup;
  public isShowOtpFormGroup = false;
  public oneTimePasswordFormGroup: FormGroup;
  public isUserNotRegistered = false;
  public isRegistrationRequired = false;
  public registerCheckboxValue = false;
  constructor(public communicationService: CommunicationService, private authService: AuthService, private router: Router,
              public modalService: BsModalService,public userService:UserService,
              public ngZone: NgZone, public cookieService: CookieService) {
  this.lastUrl =  this.cookieService.get('lastUrl');
  this.deleteAllCookies();
              }
  public toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
     }
  public ngOnInit() {
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        // tslint:disable-next-line:max-line-length
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
    });
    this.sendOtpFormGroup =  new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        // tslint:disable-next-line:max-line-length
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)      ]),
      });
    this.oneTimePasswordFormGroup =  new FormGroup({
      otpContent: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
        ]),
        });
    }
  public ngAfterViewInit() {
    this.googleInit();
}
  public onSubmit(values) {
    if (this.formGroup.valid) {
    this.authService.login(values.Email, values.Password).subscribe((res) => {
      if (res.response) {
        // this.loginedUserIsEditor(res.response.userId , res.response.adminAuthority , res.response.userName)
        this.setCookieAndNavigate(res.response.userId , res.response.adminAuthority , res.response.userName);
      }
  }, (err) => {
  });
    } else {
      validateAllFormFields(this.formGroup);
    }
}

  public onReset() {
    this.formGroup.reset();
  }
  public goToRegister() {
    this.router.navigate(['/register']);
  }
  public goToAbout() {
    this.router.navigate(['/about']);
  }
  public goToContact() {
    this.router.navigate(['/contact']);
  }
  public goToLogin() {
    this.router.navigate(['/login']);
    this.formGroup.reset();
  }
  public goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }
  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: AppConfig.GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
      });
      this.attachSignin(document.getElementById('googleBtn'));
      this.renderButton(document.getElementById('googleBtn'));
    });
  }

  public renderButton(ele) {
    gapi.signin2.render(ele, {
      longtitle: true,
      theme: 'dark',
    });
  }
  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        this.ngZone.run(() => {
          const profile = googleUser.getBasicProfile();
          this.googleToken = googleUser.getAuthResponse().id_token;
          this.googleEmail = profile.getEmail();
          this.loginWithGoogle(googleUser.getAuthResponse().id_token , profile.getEmail());
        });
      }, (error) => {

      });
  }
  public loginWithGoogle(tokenId , email) {
this.authService.loginWithGoogle(email , tokenId , false).subscribe((res) => {
  if (res.response.userId === null) {
    this.registerModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.registerCheckboxValue = false;
    this.registerModal.show();
  } else {
    // this.loginedUserIsEditor(res.response.userId , res.response.adminAuthority , res.response.userName)
    this.setCookieAndNavigate(res.response.userId, res.response.adminAuthority , res.response.userName);
  }
});
  }
  public setCookieAndNavigate(userId , adminAuthority , userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
    // this.communicationService.setEditorAuthority(isAdmin);
    if (this.lastUrl) {
    this.router.navigateByUrl(this.lastUrl);
    this.cookieService.delete('lastUrl');
    } else {
      this.router.navigate(['/home']);
    }
  }
  public registerUserFromGoogle() {
    this.authService.loginWithGoogle(this.googleEmail , this.googleToken , true).subscribe((res) => {
      this.registerModal.hide();
      this.registerCheckboxValue = false;
      // this.loginedUserIsEditor(res.response.userId , res.response.adminAuthority , res.response.userName)
      this.setCookieAndNavigate(res.response.userId, res.response.adminAuthority , res.response.userName);
    });
  }
  public onOpenOtpModal(modal) {
    this.modalRef = this.modalService.show(modal, this.config);
    this.oneTimePasswordFormGroup.reset();
    this.sendOtpFormGroup.reset();
    this.isShowOtpFormGroup = false;
    this.isUserNotRegistered = false;
    this.registerCheckboxValue = false;
    this.isRegistrationRequired = false;
  }
public onSubmitSendOtpFormGroup(value) {
    if (this.sendOtpFormGroup.valid) {
      const data = {
        password: '',
        userId: value.email,
      };
      this.authService.otpAuthorization(data , 'GENERATE' , false).subscribe((res) => {
     if (res.response === null) {
     this.isUserNotRegistered = false;
     this.isRegistrationRequired = false;
     this.isShowOtpFormGroup = true;
     } else {
     this.isUserNotRegistered = true;
     this.isRegistrationRequired = false;
    }
      });

    } else {
      validateAllFormFields(this.sendOtpFormGroup);
    }
}
public onSubmitOtpFormGroup(value) {
  if (this.oneTimePasswordFormGroup.valid) {
    const data = {
      password: value.otpContent,
      userId: this.sendOtpFormGroup.value.email,
    };

    this.authService.otpAuthorization(data , 'VERIFY' , this.isRegistrationRequired).subscribe((res) => {
    if (res.response) {
      // this.loginedUserIsEditor(res.response.userId , res.response.adminAuthority , res.response.userName)
       this.setCookieAndNavigate(res.response.userId, res.response.adminAuthority , res.response.userName);
    }
    });

  } else {
    validateAllFormFields(this.oneTimePasswordFormGroup);
  }
}
public onClickResendOtp() {
  const data = {
    password: '',
    userId: this.sendOtpFormGroup.value.email,
  };
  this.authService.otpAuthorization(data , 'RESEND' , this.isRegistrationRequired).subscribe((res) => {
  });
}
public onHideOtpModal() {
  this.oneTimePasswordFormGroup.reset();
  this.sendOtpFormGroup.reset();
  this.isShowOtpFormGroup = false;
  this.isUserNotRegistered = false;
  this.registerCheckboxValue = false;
  this.isRegistrationRequired = false;
  this.modalRef.hide();
}
public onConfirmRegisterWithOtp() {
  const data = {
    password: '',
    userId: this.sendOtpFormGroup.value.email,
  };
  this.authService.otpAuthorization(data , 'GENERATE' , true).subscribe((res) => {
    this.isUserNotRegistered = false;
    this.isShowOtpFormGroup = true;
    this.isRegistrationRequired = true;
  });
}
  public ngOnDestroy() {
  if (this.modalRef !== undefined) {
    this.modalRef.hide();
  }
}
public onOpenNotAbleToSignInModal(notAbleToSignInModal) {
this.modalRef = this.modalService.show(notAbleToSignInModal, this.config);
}
public deleteAllCookies() {
  const cookies = document.cookie.split(';');
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== 'lastUrl') {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
  }
}
 onSignIn(googleUser) {
  // var profile = googleUser.getBasicProfile();
  // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
  // console.log('Name: ' + profile.getName());
  // console.log('Image URL: ' + profile.getImageUrl());
  // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
}
}
