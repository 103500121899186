import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService  } from '../core/rest.service';
@Injectable()
export class TagService {

  constructor(private restService: RestService) {
  }
  public createTag(data) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/tag' , data , null).
      pipe(map((res: any) => res));
  }

  public getTags() {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/tag' , null).
      pipe(map((res: any) => res));
  }
  public deleteAuthor(tagName) {
    return this.restService
      .delete(AppConfig.BASE_URL_V1 + '/tag/' + tagName , true).
      pipe(map((res: any) => res));
  }
  public updateTag(tagName, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/tag/' + tagName , data , null).
      pipe(map((res: any) => res));
  }
  public addUserToTag(userId,data) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/tag/' +userId , data , null).
      pipe(map((res: any) => res));
  }
}