import { EventEmitter, Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService } from '../core/rest.service';
@Injectable()
export class TaskService {

  constructor( private restService: RestService) { }

  public getTasks(page, allTasks = false) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/task', { page, allTasks }, null).pipe(map((res: any) => res));
  }
  public getTaskData(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/article/articleDetailsByTask?taskId=' + id, true).pipe(map((res: any) => res));
  }
  public getTaskDataWithToken(id,token) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/article/articleDetailsByTask?taskId=' + id +'&token=' + token, true).pipe(map((res: any) => res));
  }
  public getTaskDetails(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/article/task/taskDetails/' + id, true).pipe(map((res: any) => res));
  }
  public submitForInitialReview(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/article/initialReview/' + taskId, data).pipe(map((res: any) => res));
  }
  public submitForCompleteInitialReview(taskId, journalId, articleId, data) {
    // tslint:disable-next-line:max-line-length
    return this.restService.put(AppConfig.BASE_URL + '/editaware/article/completeInitialReviewerTask/' + taskId + '/' + journalId + '/' + articleId, data).pipe(map((res: any) => res));
  }
  public acceptOrRejectInvitation(id, assignee, state, processid) {
    let invitationState: string;
    if (!state) {
      invitationState = 'Reject';
    } else {
      invitationState = 'Accept';
    }
    // tslint:disable-next-line:max-line-length
    return this.restService.put(AppConfig.BASE_URL + '/editaware/article/acceptOrRejectInvitation/' + id + '/' + processid + '/' + invitationState + '/' + assignee, true).pipe(map((res: any) => res));
  }
}
